import $ from 'jquery';
import { AjaxPromise } from "./ajax_utilities";
import { displayErrors } from "./common";
import {debounce} from "lodash";
import {Toast} from "./toast";

class CategoryList {
    PARENT_EL;
    DOM = {};
    TBL;

    constructor(el) {
        if(!app.LIVE) {
            app.CATEGORY_LIST = this;
        }
        this.PARENT_EL = el;
        this.TBL = this.PARENT_EL.data('tbl');
        if(!this.TBL) {
            console.warn('Failed to init category list no tbl specified.');
            return;
        }

        this.setupDOM();
        this.setupEvents(true);
    }

    setupDOM() {
        this.DOM.table = this.PARENT_EL.find('table');
        this.DOM.delete = this.DOM.table.find('.btn-js-delete');
        this.DOM.add = this.DOM.table.parent().find('.btn-js-add');

        // rows
        const $tr = this.DOM.table.find('tbody tr');

        this.DOM.tr = $tr.not('.template');
        this.DOM.tr_template = $tr.filter('.template');
        this.DOM.input_template = this.DOM.tr_template.find('input[type="text"]');
        this.DOM.inputs = this.DOM.table.find(`.category-input`);
    }

    setupEvents(init) {
        // ADD
        if( init ) {
            this.DOM.add.on('click', async (e) => {
                if( this.DOM.input_template.val() ) {
                    this.DOM.input_template.removeClass('error');
                    await this.addCategoryAjax(this.DOM.input_template.val());
                } else {
                    this.DOM.input_template.addClass('error');
                }
            });
        }

        this.DOM.tr_template.find('select').unbind('change').on('change', (e) => {
            const el = $(e.currentTarget);
            const selected = el.find('option:selected');
            if(selected.hasClass('custom-color')) {
                el.parent().find('.jscolor').show();
            } else {
                el.parent().find('.jscolor').hide();
            }
        });

        // DELETE
        this.DOM.delete.not('.btn-js').on('click', async (e) => {

            const $tr = $(e.currentTarget).closest('tr');
            const count = $tr.find('td.col_count').text().trim();
            const name = app.TBL[this.TBL].i;
            const related = {
                i: this.getRelatedName(),
                ii: `${this.getRelatedName()}(s)`,
                lower_i: this.getRelatedName().toLowerCase(),
                lower_ii: `${this.getRelatedName().toLowerCase()}(s)`
            }
            const msg = ( count === '0' || count === '-' || !count ) ? '' : `

This ${name} is currently assigned to ${count} ${related.ii}. The associated ${related.lower_ii} will now be transferred to the "Other" ${related.i} ${name}`;

            // do nothing
            if( confirm(`Are you sure you want to remove this ${name}?${msg}`) === false ) {
                return;
            }

            await this.deleteCategoryAjax($tr.data('id'), $tr);

        }).addClass('btn-js');

        // UPDATE
        $.each(this.DOM.inputs, (_k, el) => {
            el = $(el);
            el.unbind('keyup change').on('keyup change', debounce(async (e) => {
                const id = el.data('id');
                let originalInput = this.DOM.tr.not('.template').filter(`[data-id=${id}]`);
                if(!originalInput || originalInput.length === 0) {
                    const newVal = el.val();
                    if(!newVal || newVal.length === 0 || newVal === '') {
                        return;
                    }

                    await this.updateCategoryAjax(id, newVal)
                    return;
                }

                const val = originalInput.find('.hidden-input').val();
                if(val === el.val()) {
                    return;
                }

                const newVal = el.val();
                if(!newVal || newVal.length === 0 || newVal === '') {
                    el.addClass('error');
                    return;
                }

                // UPDATE
                el.removeClass('error');
                const success = await this.updateCategoryAjax(id, this.DOM.table.find(`tr[data-id="${id}"]`));
                originalInput = this.DOM.tr.not('.template').filter(`[data-id=${id}]`);
                if(originalInput && originalInput.length > 0 && success) {
                    originalInput.find('.hidden-input').val(newVal);
                }
            }, 250));
        });
    }

    addRow(id, oldData) {
        // remove any errors
        this.DOM.input_template.removeClass('error');

        const ts = new Date().valueOf().toString().substr(0,11);
        const html = this.DOM.tr_template.html()
            .replace('Add', 'Delete')
            .replace('add','delete')
            .replace('plus', 'minus')
            .replace('value=""', `value="${this.DOM.input_template.val()}"`)
            .replace(`${this.TBL}_template`, `${this.TBL}[${ts}]`);

        // append
        this.DOM.tr_template.after(`<tr data-id="${id}">${html}</tr>`);

        const tr = this.DOM.table.find(`tr[data-id="${id}"] td:last-child i`);
        const newTr = this.DOM.table.find(`tr[data-id="${id}"]`);
        tr.data('id', id);
        tr.addClass('category-input').addClass('red').removeClass('green');
        newTr.find('input, select').not('[type="hidden"]').addClass('category-input').data('id', id);
        // reset: inputs
        this.DOM.tr_template.find('input, select').each((_k, el) => {
            el = $(el);
            if(el.attr('type') === 'checkbox' && el.prop('checked') === true) {
                newTr.find(`[name="${el.attr('name')}"]`).prop('checked', true).addClass('category-input').removeClass('needed');
            } else {
                newTr.find(`[name="${el.attr('name')}"]`).val(el.val()).addClass('category-input').removeClass('needed');
            }

            el.val('').trigger('change');
            el.prop('checked', false).trigger('change');
        });

        // get dom object
        this.setupDOM();
        this.setupEvents(false);
    }

    getRelatedName() {
        switch (this.TBL) {
            case 'cos_sup':
            case 'cos_typ':
                return 'Cost';
            case 'doc_cat':
                return 'Document';
            case 'evt_typ':
                return 'Event';
            default: return '';
        }
    }

    async updateCategoryAjax(toUpdate, parentEl) {
        try {
            const serialized = this.serializeData(parentEl);
            if(serialized.hasErrors) {
                return;
            }

            const data = { categoryId: toUpdate, name: parentEl.find(`input[name*="${this.TBL}["]`).val(), tbl: this.TBL, ...serialized.data };
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX}category/edit`,
                method: 'POST',
                data: data,
            });

            if(res.status !== 'success') {
                Toast.error(`An unexpected error occurred when updating ${app.TBL[this.TBL].i}.`);
                return false;
            }

            Toast.success(`Changes saved.`);
            return true;
        } catch(err) {
            Toast.error(`An unexpected error occurred when updating ${app.TBL[this.TBL].i}.`);
            console.warn('Unexpected AJAX Error', err);
            return false;
        }
    }

    async deleteCategoryAjax(toDelete, tr) {
        try {
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX}category/delete`,
                method: 'POST',
                data: {
                    categoryId: toDelete,
                    tbl: this.TBL,
                }
            });

            if(res.status !== 'success') {
                Toast.error(`An unexpected error occurred when trying to delete ${app.TBL[this.TBL].i}.`);
                console.warn('Unexpected AJAX Response', res);
                return;
            }

            if(tr) {
                tr.fadeOut('slow').remove();
            }

            Toast.success(`${app.TBL[this.TBL].i} deleted successfully.`);
        } catch(err) {
            Toast.error(`An unexpected error occurred when trying to delete ${app.TBL[this.TBL].i}.`);
        }
    }

    async addCategoryAjax(name) {
        try {
            const serialized = this.serializeData();
            if(serialized.hasErrors) {
                return;
            }

            const data = { tbl: this.TBL, name: name, ...serialized.data };
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX}category/add`,
                method: 'POST',
                data: data,
            });

            if(res.status !== 'success' || !res.data) {
                Toast.error(`An unexpected error occurred when creating new ${app.TBL[this.TBL].i}.`);
                console.warn('Unexpected AJAX Response', res);
                return;
            }

            this.addRow(res.data, data);
            Toast.success(`${app.TBL[this.TBL].i} added successfully.`);
        } catch(err) {
            Toast.error(`An unexpected error occurred when creating new ${app.TBL[this.TBL].i}.`);
            console.warn('Unexpected AJAX Error', err);
        }
    }

    serializeData(parentEl = null) {
        let data = {};
        let hasErrors = false;
        if(!parentEl || parentEl.length === 0) {
            parentEl = this.DOM.tr_template;
        }

        $.each(parentEl.find('input, select'), (_k, el) => {
            el = $(el);
            if(!el.val() || el.val().length === 0) {
                if(el.hasClass('needed')) {
                    el.addClass('error');
                    hasErrors = true;
                }
                return;
            }

            el.removeClass('error');
            if(el.attr('type') === 'checkbox' && !el.is(':checked')) {
                data[el.attr('name')] = '0';
                return;
            } else if(el.attr('type') === 'checkbox') {
                data[el.attr('name')] = '1';
                return;
            }

            data[el.attr('name')] = el.val();
        });
        return {
            hasErrors,
            data
        };
    }
}

$(() => {
    app.DOM.content.find('.cat-generic-list').each((_k, el) => {
        new CategoryList($(el));
    });
});